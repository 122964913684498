import jsPDF from "jspdf"
// import addFont from "../../../../fonts/fonts"
import 'jspdf-autotable'
import moment from "moment"
import addFont from "../../../../../assets/fonts/fonts"

import mo from '../../../../../assets/images/pdfLogos/MO.png'
import lms from '../../../../../assets/images/pdfLogos/LMS.png'
import guk from '../../../../../assets/images/pdfLogos/GUK.png'

const orientation = 'p'

export const getRatingByCategoryReport = async ({data, comission, userInfo}) => {

  let lastY = 35
  
  const {last_name, first_name, patronymic} = userInfo
  const createrName = `${last_name || ''} ${first_name || ''} ${patronymic || ''}`
  
  const getY = (indent, newValue) => {
    let newY = newValue ? newValue + indent : lastY + indent
    if (newY > doc.internal.pageSize.height - 15) {
      doc.addPage('a4', orientation)
      newY = 35
    }
    lastY = newY
    return newY
  }
  
  const addLogos = () => {
    const pageCount = doc.internal.getNumberOfPages()
    for (var i = 1; i <= pageCount; i++) {
      doc.setPage(i)
      
      doc.addImage(guk, 'PNG', 10, 5, 20, 25)
      doc.addImage(mo, 'PNG', 95, 3, 20, 12)
      doc.addImage(lms, 'PNG', 190, 285, 15, 9)

      doc.setFontSize(9)
      doc.text('Министерство обороны Российской Федерации', 105, 20, 'center')
      doc.text('Управление военного образования Главного управления кадров', 105, 25, 'center')

      doc.setFontSize(8)
      doc.text(`Кто создал: ${createrName || ''}`, 10, 292)
      doc.text(`Дата: ${moment(comission.date_to).format('DD.MM.YYYY')}`, 10, 295)
      doc.text(`Лист ${i} из ${pageCount}`, 100, 295, 'center')
      doc.text('Документ сформирован в системе управления обучением', 100, 290)

    }
  }

  addFont(jsPDF.API)
  const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation })
  doc.setFont('Montserrat').setFontSize(12)

  doc.text('Справка-рейтинг', 105, lastY, 'center')
  doc.text('по итогам экспертизы аттестационных материалов (портфолио)', 105, getY(5), 'center')
  doc.text('педагогических работников ДОУ УВО ГУК МО РФ', 105, getY(5), 'center')
  doc.text(`${moment().format('MMMM')} ${moment().year()}`, 105, getY(5), 'center')
  doc.text('1.  Рейтинг по занимаемой педагогической должности', 105, getY(10), 'center')

  doc.autoTable({
    html: `#ratingByCategoryTable`,
    styles: { font: 'Montserrat', fontSize: 8 },
    headStyles: {
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.2,
      lineColor: 100,
      fontSize: 8,
    },
    bodyStyles: {
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.2,
      lineColor: 100,
      fontSize: 8,
      textColor: '#000',
    },
    willDrawCell: ({cell}) => {
      const firstCondition = cell.colSpan === 7
      const secondCondition = !['Количество', 'соответствие', 'повышены', 'снижены'].map(word => cell.text[0]?.includes(word)).includes(true)
      firstCondition && secondCondition && doc.setFillColor('#b8e3fb')
    },
    startY: getY(10),
    margin: {top: 35}
  })

  doc.text('Общее количество:', 20, getY(10, doc.autoTableEndPosY()))
  doc.text('соответствие выставленных баллов', 20, getY(5))
  doc.text('баллы повышены', 20, getY(5))
  doc.text('баллы снижены', 20, getY(5))
  
  doc.text('2.  Рейтинг по квалификационным категориям', 105, getY(10), 'center')

  doc.autoTable({
    html: `#ratingByCategorySecondTable`,
    styles: { font: 'Montserrat', fontSize: 8 },
    headStyles: {
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.2,
      lineColor: 100,
      fontSize: 8,
    },
    
    bodyStyles: {
      halign: 'center',
      valign: 'middle',
      lineWidth: 0.2,
      lineColor: 100,
      fontSize: 8,
      textColor: '#000',
    },
    startY: getY(10),
    margin: {top: 35}
  })

  doc.setFont(undefined, 'bold')
  doc.text('Руководитель экспертной группы                                                            Близнова Н.А.', 20, getY(15, doc.autoTableEndPosY()))
  doc.setFont(undefined, 'normal')

  addLogos()

  doc.addPage('a4', orientation)
  doc.deletePage(doc.getNumberOfPages())
  return doc
}