import React from "react";
import { BrowserRouter, Route, Routes as Switch} from "react-router-dom";

import Layout from "../components/Layout/Layout";
import Teacher from '../pages/Teacher/Teacher'
import Auth from '../pages/Auth/Auth'
import Expert from '../pages/Expert/Expert'
import Request from '../pages/Request'
import SharedFiles from '../pages/SharedFiles/SharedFiles'

export const PATHS = {
    ROOT: '/',
    AUTH: {
      TEACHER: `/teacher`,
      EXPERT: `/expert`,
      GUK: `/guk`,
      REQUEST: `/request/:requestId`,
      SHARED_FILES: `/shared_files`,
    },
  };
  
  export default function Routes() {
    return (
      <BrowserRouter>
          <Layout>
            <Switch>
              <Route exact path={PATHS.ROOT} element={<Auth/>} />
              <Route exact path={PATHS.AUTH.TEACHER} element={<Teacher/>} />
              <Route exact path={PATHS.AUTH.EXPERT} element={<Expert/>} />
              <Route exact path={PATHS.AUTH.GUK} element={<Expert/>} />
              <Route exact path={PATHS.AUTH.REQUEST} element={<Request/>} />
              <Route exact path={PATHS.AUTH.SHARED_FILES} element={<SharedFiles/>} />
            </Switch>
          </Layout>
      </BrowserRouter>
    );
  }