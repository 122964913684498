import React from 'react'
import styles from '../styles.module.scss'
import { FaFilePdf } from "react-icons/fa";
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import addFont from '../../../assets/fonts/fonts'
import Button from '../../../components/Button'
import { getInitials } from '../../../utils/smallHelpers';
import moment from 'moment';

const COMISSION_MANAGEMENT = ['Председатель комиссии', 'Заместитель председателя комиссии', 'Секретарь']

export default function PDFPrint ({ data, organization, specializations, comission, totalPoints, comissionInfo }) {
  
  const getCategoryWord = (category) => category === 'первая' ? 'первой' : 'высшей'

  const positionGroup = specializations?.find(spec => spec.id === data?.position_id)?.positions_list
  const positionGroupFormatted = positionGroup ? `(${positionGroup.replace(',', ', ')})` : ''

  const createDocData = () => {
    
    const obgTeacher = data.position_id === 10
    let lastY = 10
  
    const getY = (indent, newValue) => {
      let newY = newValue ? newValue + indent : lastY + indent
      if (newY > doc.internal.pageSize.height - 20) {
        doc.addPage('a4', orientation)
        newY = 10
      }
      lastY = newY
      return newY
    }


    addFont(jsPDF.API)
    const orientation = 'l'
    const doc = new jsPDF({ orientation });
    doc.setFont("Montserrat");

    doc.setFontSize(10).setFont(undefined, 'bold')
    doc.text("Экспертное заключение об уровне профессиональной деятельности педагогического работника", 150, lastY, "center" )
    obgTeacher && doc.text("(преподавателя, преподавателя-организатора основ безопасности и защиты Родины, инструктора по физической культуре)", 150, getY(5), "center" )
    doc.text(positionGroupFormatted, 150, getY(5), "center" )
    doc.setFont(undefined, 'normal')
    !data.simple_cert && doc.text(`Необходимое количество баллов: ${data?.point} `, 150, getY(5), "center" )
    


    doc.text(`ФИО: ${data.fio}`, 15, getY(10))
    doc.text(`Место работы: ${organization?.replace('\r\n', ' ')}`, 15, getY(5))
    doc.text(`Занимаемая должность: ${data.position}`, 15, getY(5))

    doc.autoTable({
      html: `#criterionsTable`,
      styles: { font: 'Montserrat', fontSize: 8 },
      headStyles: {
        // halign: 'center',
        // valign: 'middle',
        lineWidth: 0.2,
        lineColor: 100,
        fontSize: 8,
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0], 
        fillOpacity: 0.5 
      },
      bodyStyles: {
        // halign: 'center',
        // valign: 'middle',
        lineWidth: 0.2,
        lineColor: 100,
        fontSize: 8,
        textColor: '#000',
        fillColor: [255, 255, 255]
      },
      columnStyles: {
        0: { cellWidth: 70 },
      },
      willDrawCell: function ({cell}) {
        if (cell.colSpan > 1) {
          cell.styles.valign = 'middle'
          cell.styles.halign = 'center'
          cell.styles.fontStyle = 'bold'
        }
        doc.setFillColor('white');
     },
      startY: getY(10)
    })

    !data.simple_cert && doc.text(`Общее количество баллов: ${totalPoints || 0} / ${data.point || 0}`, 13, getY(10, doc.autoTableEndPosY()))

    doc.setFontSize(9).text(`Общее заключение: на основании оценки профессиональной деятельности ${data.position} учебного курса ${data.fio} сделать вывод, что`, 13, data.simple_cert ? getY(10, doc.autoTableEndPosY()) : getY(10))
    doc.text(`уровень его квалификации ${data.point > totalPoints ? 'не' : ''} соответствует требованиям, предъявляемым к ${getCategoryWord(data.category)} квалификационной категории.`, 13, getY(5))

    if (!data.simple_cert) {
      comission?.slice(0, 3).forEach((manager, ind) => {
        const yOffset = ind ? 10 : 15
        const positionFio = `${manager.position}: ${manager.expert_fio}`
        doc.setFont(undefined, 'bold').text(positionFio, 13, getY(yOffset))
        doc.line(130, lastY, 200, lastY)
        doc.setFont(undefined, 'normal').text(getInitials(manager.expert_fio), 245, lastY)
        doc.setFontSize(7).text('(подпись)', 160, getY(3))
        doc.setFontSize(9)
      })
      
      doc.setFont(undefined, 'bold').text(`Члены комиссии:`, 150, getY(15), 'center')
      doc.setFont(undefined, 'normal')
      
      comission?.slice(3).forEach((manager, ind) => {
        doc.text(manager.expert_fio, 13, getY(10))
        doc.line(70, lastY, 230, lastY)
        doc.text(getInitials(manager.expert_fio), 245, lastY)
        doc.setFontSize(7).text('(должность, подпись)', 140, getY(3))
        doc.setFontSize(9)
      })
    }

    doc.setFontSize(9).text(`Дата проведения экспертизы: ${moment(comissionInfo?.date_to).format('DD.MM.YYYY')}`, 13, getY(15))

    doc.addPage("a4", orientation);
    doc.deletePage(doc.getCurrentPageInfo().pageNumber);

    return doc
  }
  
  const toPdf = () => {
    const doc = createDocData()
    doc.save(`Заявление на аттестацию.pdf`)
  };
  
  return (
    <div className={styles.pdfBlock}>
      <Button style={{backgroundColor: 'rgb(223 7 8)'}} label='экспертное заключение' onClick={toPdf}>
        <FaFilePdf size={18}/>
      </Button>
    </div>
  )
}
